<template>
  <v-app>
    <v-container style="background-color: #fdfdfd" fill-height fluid>
      <v-row align="center"
             justify="center">
        <v-col md="4">
          <v-card rounded elevation="20">
            <v-card-title>
              {{$t('browser.notSupported')}}
            </v-card-title>
            <v-card-text>
              {{$t('browser.message')}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#36dafa" href="https://www.microsoft.com/edge" target="_blank">
                {{$t('browser.edgeDownload')}}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "NotSupportedBrowser",
  data() {
    return {

    }
  },
}
</script>